<script>
import { mapState, mapMutations } from 'vuex'
import { EPMC_ROOT } from '@/config'
import {
  fetchArticles,
  fetchCitationsExport,
  fetchFulltextXmlFromRepo,
  transformXml,
} from '@/api'
import {
  createCitationFromAbstract,
  createCitationTextFromAbstract,
} from '@/helpers/citation'
import { registerMatomoEvent } from '@/helpers/matomo'
// the interface of downloadFile can be improved
import { downloadFile } from '@/helpers/file'
import { Action, Notification, Toggle } from 'epmc-patterns/components/v2'
import ExportList from './ExportList'
import { isValidSearchPath } from '@/helpers/search'

const MAX_EXPORT_NUM = 50000
const DEFAULT_EXPORT_NUM = 150
const SORT_OPTIONS = {
  'FIRST_PDATE_D+desc': 'DATE_DESC',
  'FIRST_PDATE_D+asc': 'DATE_ASC',
  'FIRST_IDATE_D+desc': 'REV_DATE_DESC',
  'CITED+desc': 'TIMES_CITED_DESC',
  RELEVANCE: 'RELEVANCE',
}

// maybe can be put into the api file
const xmlToString = (xml) => {
  if (window.ActiveXObject) {
    return xml.xml
  } else {
    return new XMLSerializer().serializeToString(xml)
  }
}

export default {
  components: { Action, ExportList, Notification, Toggle },
  data() {
    return {
      quickSaveNotificationText: '',
      exportListOptions: [
        {
          text: 'Export list',
          selected: false,
          disabled: false,
        },
        {
          text: 'Search results',
          selected: false,
        },
      ],
      exportNumber: 0,
      selectedExportFormat: null,
      downloadNotificationText: '',
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('exportList', ['exportList']),
    ...mapState('search', ['searchParams']),
    page() {
      return this.$route.name
    },
    matomoCategory() {
      const { page } = this
      return page[0].toUpperCase() + page.slice(1, page.length)
    },
    addedToExportList() {
      return this.exportList.find(
        (citation) => citation.id === this.abstract.id
      )
    },
    email() {
      return (
        'mailto:?subject=Europe PMC citation&body=' +
        encodeURIComponent(this.citationText)
      )
    },
    citationText() {
      return createCitationTextFromAbstract(this.abstract)
    },
    maxExportNumber() {
      const {
        searchParams: { hitCount },
      } = this
      return hitCount > MAX_EXPORT_NUM ? MAX_EXPORT_NUM : hitCount
    },
    exportNumberErrorNotificationText() {
      const { exportNumber, maxExportNumber } = this
      let exportNumberErrorNotificationText = ''
      if (parseInt(exportNumber) === 0 || !exportNumber) {
        exportNumberErrorNotificationText = 'Cannot export 0 citations.'
      } else if (
        isNaN(exportNumber) ||
        !Number.isInteger(exportNumber * 1) ||
        exportNumber < 0
      ) {
        exportNumberErrorNotificationText =
          'Input must be an non-negative integer.'
      } else if (exportNumber > maxExportNumber) {
        exportNumberErrorNotificationText =
          'Unable to export more than ' +
          maxExportNumber.toLocaleString() +
          ' citations.'
      }
      return exportNumberErrorNotificationText
    },
    // the export file in the article page is generated in the browswer;
    // the export file in the search page is generated in the browser for selected citations
    // the export file in the search page is generated in the server for search results
    // xsl is used if not returning xml
    exportFormatOptions() {
      const { abstract, page } = this
      return [
        {
          label: 'BibTex (Mendeley, Papers)',
          value: 'BIBTEXT',
          xsl: 'rest2bibtex',
          ext: 'bib',
        },
        {
          label: 'RIS (EndNote, Mendeley, Refworks)',
          value: 'RIS',
          xsl: 'rest2ris',
          ext: 'ris',
        },
        {
          label: 'Text (citation)',
          value: 'TEXT_CITATION',
          xsl: 'rest2textcitation',
          ext: 'txt',
        },
        {
          label: 'XML (abstracts)',
          value: 'EUROPEPMC_XML',
          ext: 'xml',
        },
        // display in article page only when there is open access full text
        {
          label: 'XML (open access full text)',
          value: 'EUROPEPMC_FULLTEXT_XML',
          ext: 'xml',
          hide: page === 'article' && abstract.isOpenAccess === 'N',
        },
        // csv and idlist do not display in article page
        {
          label: 'Excel, comma separated (CSV)',
          value: 'CSV',
          xsl: 'rest2csv',
          ext: 'csv',
          hide: page === 'article',
        },
        {
          label: 'ID list',
          value: 'ID_LIST',
          xsl: 'rest2ids',
          ext: 'txt',
          hide: page === 'article',
        },
      ]
    },
    downloadDisabled() {
      const {
        exportListOptions,
        exportNumberErrorNotificationText,
        page,
        selectedExportFormat,
      } = this
      const exportSearch =
        isValidSearchPath(page) && exportListOptions[1].selected
      if (exportSearch) {
        return exportNumberErrorNotificationText || !selectedExportFormat
      } else {
        return !selectedExportFormat
      }
    },
    showExportList() {
      const { exportList, exportListOptions, page } = this
      return (
        (isValidSearchPath(page) &&
          exportListOptions[0].selected &&
          exportList.length) ||
        (page === 'article' && exportList.length)
      )
    },
  },
  watch: {
    exportList() {
      this.initExportOptions()
    },
  },
  created() {
    this.initExportOptions()
    const {
      searchParams: { hitCount },
    } = this
    this.exportNumber =
      hitCount > DEFAULT_EXPORT_NUM ? DEFAULT_EXPORT_NUM : hitCount
  },
  methods: {
    ...mapMutations('exportList', ['addSelectedArticle']),
    registerMatomoEvent,
    initExportOptions() {
      const {
        exportList,
        exportListOptions,
        searchParams: { hitCount },
      } = this
      const { length: exportListLength } = exportList

      exportListOptions[0].text =
        'Export list (' + exportListLength.toLocaleString() + ')'
      exportListOptions[0].selected = exportListLength
      exportListOptions[0].disabled = exportListLength === 0
      exportListOptions[1].text =
        'Search results (' + hitCount.toLocaleString() + ')'
      exportListOptions[1].selected = !exportListLength
    },
    scheduleNotification(text, duration = 3000) {
      this.quickSaveNotificationText = text
      setTimeout(() => {
        this.quickSaveNotificationText = ''
      }, duration)
    },
    addToExportList() {
      this.addSelectedArticle(createCitationFromAbstract(this.abstract))
      this.scheduleNotification('Citation added to your export list')
      registerMatomoEvent(this.matomoCategory, 'Export', 'Add to export list')
    },
    copy() {
      const text = this.$refs.citationText
      const range = document.createRange()
      range.selectNode(text)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      this.scheduleNotification('Citation copied to your clipboard')
      registerMatomoEvent(this.matomoCategory, 'Export', 'Copy')
    },
    onLabelSelected(index) {
      const { matomoCategory, exportListOptions } = this
      exportListOptions.forEach((op) => (op.selected = false))
      exportListOptions[index].selected = true
      this.downloadNotificationText = ''
      registerMatomoEvent(
        matomoCategory,
        'Export - toggle',
        exportListOptions[index].text
      )
    },
    handleChangeFormat(option) {
      const { matomoCategory, page } = this
      this.selectedExportFormat = option
      if (option.value === 'EUROPEPMC_FULLTEXT_XML') {
        this.downloadNotificationText =
          'Only open-access, full-text articles will be exported'
      } else {
        this.downloadNotificationText = ''
      }
      registerMatomoEvent(
        matomoCategory,
        isValidSearchPath(page) ? 'Export - Select format' : 'Export - format',
        option.value
      )
    },
    async exportCitations() {
      const {
        abstract,
        matomoCategory,
        exportList,
        exportListOptions,
        exportNumber,
        page,
        searchParams: { query: searchQuery, sort: searchSort },
        selectedExportFormat: format,
      } = this

      this.downloadNotificationText = 'Export in process'

      if (page === 'article') {
        if (format.value === 'EUROPEPMC_FULLTEXT_XML') {
          const response = await fetchFulltextXmlFromRepo(
            abstract.pmcid || abstract.id
          )
          downloadFile(
            xmlToString(response),
            format.ext,
            'europepmc.' + format.ext
          )
        } else {
          const response = await fetchArticles({
            articles: [
              {
                id: abstract.id,
                source: abstract.source,
              },
            ],
            format: 'xml',
            resultType: 'core',
            ws: true,
          })
          const xml = format.xsl
            ? await transformXml(response, format.xsl)
            : response
          const str = xmlToString(xml)
          // if (format.value === 'RIS') {
          //   const lines = str.split('\n')
          //   str = lines.slice(3, lines.length).join('\n')
          // }
          downloadFile(str, format.ext, 'europepmc.' + format.ext)
        }
      } else if (isValidSearchPath(page)) {
        if (exportListOptions[0].selected) {
          const citations =
            format.value === 'EUROPEPMC_FULLTEXT_XML'
              ? exportList.filter((citation) => citation.isOpenAccess === 'Y')
              : exportList
          const response = await Promise.all(
            citations.map((citation) =>
              fetchArticles({
                articles: [
                  {
                    id: citation.id,
                    source: citation.source,
                  },
                ],
                format: 'xml',
                resultType: 'core',
                ws: true,
              })
            )
          )
          let str = ''
          for (let i = 0; i < response.length; i++) {
            const xml = format.xsl
              ? await transformXml(response[i], format.xsl)
              : response[i]

            let append = format.xsl
              ? xmlToString(xml)
              : xml.getElementsByTagName('result')[0].outerHTML
            switch (format.value) {
              case 'CSV':
                if (i !== 0) {
                  append = append.split('\n')[1]
                }
                break
              case 'RIS': {
                const lines = append.split('\n')
                if (i !== 0) {
                  append = lines.slice(3, lines.length).join('\n')
                }
                break
              }
            }
            str += append
            // if (i !== 0 && format.value === 'RIS') {
            //   str = str.replace('Provider: Europe PMC', '')
            //   str = str.replace('Content: text/plain; charset="UTF-8"', '')
            // }
            if (i !== response.length - 1) {
              str += '\n'
            }
          }
          if (!format.xsl) {
            str = '<response>' + str + '</response>'
          }
          downloadFile(str, format.ext, 'europepmc.' + format.ext)
        } else if (exportListOptions[1].selected) {
          const data = {
            query: searchQuery,
            synonym: false,
            sortBy: searchSort ? SORT_OPTIONS[searchSort] : 'RELEVANCE',
            max: exportNumber,
            format: format.value,
            idList: [],
          }
          const response = await fetchCitationsExport(data)
          if (response.status === 0) {
            const exportLink = document.getElementById('export-link')
            exportLink.href =
              EPMC_ROOT + 'api/get/export?ts=' + new Date().getTime()
            exportLink.onclick = () => {}
            exportLink.click()
          }
        }
      }

      this.downloadNotificationText = ''

      registerMatomoEvent(matomoCategory, 'Export', 'Download')
      if (isValidSearchPath(page) && exportListOptions[1].selected) {
        registerMatomoEvent(
          matomoCategory,
          'Export - no. results',
          exportNumber
        )
      }
    },
  },
}
</script>
<template>
  <div id="export--main--panel" class="export-content">
    <div v-if="page === 'article'" class="export-quick-save">
      <h3>
        Quick save
        <div id="quick-save-buttons">
          <action :disabled="addedToExportList" @click="addToExportList">
            <i slot="icon" class="fas fa-plus" />Add to export list
          </action>
          <action @click="copy">
            <i slot="icon" class="fas fa-copy" />Copy
          </action>
          <action
            :href="email"
            @click="registerMatomoEvent('Article', 'Export', 'Email')"
          >
            <i slot="icon" class="fas fa-envelope" />Email
          </action>
        </div>
      </h3>
      <notification
        v-if="quickSaveNotificationText"
        notification-style="confirmation"
      >
        {{ quickSaveNotificationText }}
      </notification>
      <div id="exportCitation" ref="citationText">
        {{ citationText }}
      </div>
      <h3>Or, select format and export</h3>
    </div>

    <template :v-else-if="isValidSearchPath(page)">
      <h3>1. Select citations for export?</h3>
      <toggle :labels="exportListOptions" @onLabelSelected="onLabelSelected" />
      <div v-if="exportListOptions[1].selected" id="export-number-input">
        Export the first
        <input v-model="exportNumber" type="text" />
        results (up to {{ maxExportNumber }})
        <notification
          v-if="exportNumberErrorNotificationText"
          notification-style="error"
        >
          {{ exportNumberErrorNotificationText }}
        </notification>
      </div>
      <h3>2. Select format</h3>
    </template>

    <div class="export-format-buttons">
      <div
        v-for="option in exportFormatOptions.filter((op) => !op.hide)"
        :key="option.value"
      >
        <input
          :id="'export--text--format-' + option.value"
          name="exportformat"
          type="radio"
          :value="option"
          @change="handleChangeFormat(option)"
        />
        <label :for="'export--text--format-' + option.value">{{
          option.label
        }}</label>
      </div>
    </div>

    <notification v-if="downloadNotificationText" notification-style="warning">
      {{ downloadNotificationText }}
    </notification>
    <div>
      <input
        id="export--start--download"
        type="button"
        value="Download"
        :class="{ disabled: downloadDisabled }"
        @click.prevent="exportCitations"
      />
      <a id="export-link" href="#" class="alt-text" download>Export</a>
    </div>

    <export-list v-if="showExportList" @remove-articles="onLabelSelected(1)" />
  </div>
</template>
<style lang="scss" scoped>
#export--main--panel {
  h3:first-of-type {
    margin-top: 0;
  }
  .notification {
    margin: ($base-unit * 4) 0;
  }
  #quick-save-buttons {
    float: right;
    .action:not(:last-child) {
      margin-right: $base-unit * 2;
    }
  }
  #export-number-input {
    margin-top: $base-unit * 6;
    input {
      width: $base-unit * 12;
    }
  }
  .export-format-buttons {
    margin-bottom: $base-unit * 4;
  }
}
</style>
