<script>
import Sticky from '@/helpers/sticky'
export default {
  mixins: [Sticky],
  props: {
    color: {
      type: String,
      default: '',
    },
  },
}
</script>
<template>
  <div class="popup-shadow sticky" @click="$emit('unmount')">
    <div class="shadow" :style="color ? `background-color:${color}` : ''" />
  </div>
</template>
<style scoped lang="scss">
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.popup-shadow {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .shadow {
    background-color: $epmc-dark-transparent-gray;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
  }
}
</style>
