import { getDate } from 'epmc-patterns/helpers'

// may need to improve it later
// what if the limit of recent history or export list is large
export function createCitationFromAbstract(abstract) {
  const citation = {}

  if (abstract) {
    citation.id = abstract.id
    citation.source = abstract.source
    if (abstract.pmid) citation.pmid = abstract.pmid
    if (abstract.pmcid) citation.pmcid = abstract.pmcid
    if (abstract.title) citation.title = abstract.title
    if (abstract.authorString) citation.authorString = abstract.authorString
    if (abstract.journalInfo) {
      if (abstract.journalInfo.issue)
        citation.issue = abstract.journalInfo.issue
      if (abstract.journalInfo.volume)
        citation.journalVolume = abstract.journalInfo.volume
      if (abstract.journalInfo.journal) {
        if (abstract.journalInfo.journal.title)
          citation.journalTitle = abstract.journalInfo.journal.title
        if (abstract.journalInfo.journal.isoabbreviation)
          citation.journalAbbreviation =
            abstract.journalInfo.journal.isoabbreviation
      }
    }
    if (abstract.pubYear) citation.pubYear = abstract.pubYear
    if (abstract.pageInfo) citation.pageInfo = abstract.pageInfo
    if (abstract.pubTypeList && abstract.pubTypeList.pubType)
      citation.pubType = abstract.pubTypeList.pubType.join('; ')
    if (abstract.bookOrReportDetails)
      citation.bookOrReportDetails = Object.assign(
        {},
        abstract.bookOrReportDetails
      )
    citation.isOpenAccess = abstract.isOpenAccess
    citation.inEPMC = abstract.inEPMC
    citation.hasBook = abstract.hasBook
    citation.citedByCount = abstract.citedByCount
    if (abstract.firstPublicationDate)
      citation.firstPublicationDate = abstract.firstPublicationDate
  }

  return citation
}

export function createCitationTextFromAbstract(abstract) {
  let citation = ''

  if (abstract.authorList) {
    const authors =
      abstract.authorList.author.length > 5
        ? abstract.authorList.author.slice(0, 3)
        : abstract.authorList.author
    authors.forEach(function (auth, index) {
      citation += auth.collectiveName ? auth.collectiveName : auth.fullName
      citation += index + 1 < authors.length ? ', ' : ''
    })
    citation += abstract.authorList.author.length > 5 ? ', et al. ' : '. '
  }
  citation += abstract.title
  if (abstract.journalInfo) {
    citation += ' ' + abstract.journalInfo.journal.title + '. '
    citation += abstract.journalInfo.dateOfPublication
    citation += abstract.journalInfo.volume
      ? ';' + abstract.journalInfo.volume
      : ''
    citation += abstract.journalInfo.issue
      ? '(' + abstract.journalInfo.issue + ')'
      : ''
    citation += abstract.pageInfo ? ':' + abstract.pageInfo + '.' : '.'
  } else if (abstract.bookOrReportDetails) {
    citation +=
      abstract.bookOrReportDetails.comprisingTitle &&
      abstract.bookOrReportDetails.comprisingTitle !== abstract.title
        ? '. In: ' + abstract.bookOrReportDetails.comprisingTitle + '. '
        : '. '
    citation += abstract.bookOrReportDetails.edition
      ? abstract.bookOrReportDetails.edition + ' ed. '
      : ''
    citation += abstract.bookOrReportDetails.publisher + '; '
    citation += abstract.bookOrReportDetails.yearOfPublication + '.'
    citation += abstract.pageInfo ? 'p. ' + abstract.pageInfo + '.' : ''
  } else if (abstract.patentDetails) {
    citation +=
      '. ' +
      abstract.patentDetails.country +
      ' ' +
      abstract.patentDetails.typeDescription +
      ' ' +
      abstract.patentDetails.application.applicationNumber +
      '. '
    citation +=
      getDate(abstract.patentDetails.application.applicationDate) + '. '
  }
  citation += abstract.doi ? ' DOI: ' + abstract.doi + '.' : ''
  const ids = []
  if (abstract.pmid) {
    ids.push('PMID: ' + abstract.pmid)
  }
  if (abstract.pmcid) {
    ids.push('PMCID: ' + abstract.pmcid)
  }
  citation += ids.length ? ' ' + ids.join('; ') + '.' : ''

  return citation
}
