<script>
import { mapState, mapActions } from 'vuex'
import { createCitationFromAbstract } from '@/helpers/citation'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Loading, Notification } from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'

const MODAL_LOADING_TIMEOUT_IN_MILLISECONDS = 6000
const ORCID_AUTH_TIMEOUT_IN_MILLISECONDS = 45000

const getOrcidAuthUrl = () => {
  return window.location.origin
}

export default {
  components: { Citation, Loading, Notification },
  data() {
    return {
      loading: false,
      showOrcidAuthTimeoutNotification: false,
      signedIn: document.getElementById('profile-bar--username') !== null,
    }
  },
  computed: {
    ...mapState('article/abstract', ['abstract']),
    ...mapState('orcid', [
      'claimNotification',
      'loggedInOrcidId',
      'orcidAuthorized',
    ]),
    citation() {
      return createCitationFromAbstract(this.abstract)
    },
  },
  created() {
    const handleOrcidLoginEvent = (event) => {
      const authSucceeded = event.data
      if (
        authSucceeded !== null &&
        event.origin.includes(window.location.host)
      ) {
        if (authSucceeded === 'true') {
          if (this.signedIn) {
            if (!this.$route.query.claimWithOrcid) {
              this.$router.push({ query: { claimWithOrcid: true } })
            }
          } else {
            window.location.href =
              window.location.origin +
              window.location.pathname +
              '?claimWithOrcid'
          }
        }
      }

      const iframe = document.getElementById('orcid-auth-redirect')
      if (iframe) {
        iframe.parentNode.removeChild(iframe)
      }
      localStorage.removeItem('ORCID_AUTH_SUCCEEDED')
    }

    // IE support for cross tab communication
    window.addEventListener('storage', () => {
      const data = localStorage.getItem('ORCID_AUTH_SUCCEEDED')
      if (data != null) {
        handleOrcidLoginEvent({
          data: data,
          origin: window.location.href,
        })
      }
    })

    window.addEventListener('message', handleOrcidLoginEvent)

    if (this.signedIn) {
      this.loading = true
      setInterval(() => {
        this.loading = false
      }, MODAL_LOADING_TIMEOUT_IN_MILLISECONDS)
      this.checkIfAuthorizedToClaim(this.citation).then(() => {
        if (this.orcidAuthorized) {
          this.loading = false
        } else {
          try {
            const iframe = document.createElement('iframe')
            iframe.setAttribute('id', 'orcid-auth-redirect')
            iframe.style.display = 'none'
            iframe.style.border = 'none'
            iframe.width = 0
            iframe.height = 0
            iframe.src = getOrcidAuthUrl() + '/orcid/import?headless=true'
            document.body.appendChild(iframe)
          } catch (e) {
            this.loading = false
          }
        }
      })
    }

    this.registerMatomoEventForArticle('Claim to ORCID', 'Open popup')
  },
  methods: {
    ...mapActions('orcid', ['claimToOrcid', 'checkIfAuthorizedToClaim']),
    registerMatomoEventForArticle(eventAction, eventName) {
      registerMatomoEvent('Article', eventAction, eventName)
    },
    initOrcidAuth() {
      window.open(
        getOrcidAuthUrl() + '/orcid/import?headless=true',
        'orcid_login'
      )
      setInterval(() => {
        if (!this.signedIn || !this.orcidAuthorized) {
          this.showOrcidAuthTimeoutNotification = true
        }
      }, ORCID_AUTH_TIMEOUT_IN_MILLISECONDS)
      this.registerMatomoEventForArticle(
        'Claim to ORCID',
        'Select claim button'
      )
    },
    // logged in but not claiming yet
    async claimOrcid() {
      await this.claimToOrcid(this.citation)
      this.registerMatomoEventForArticle(
        'Claim to ORCID',
        'Select claim button'
      )
      this.registerMatomoEventForArticle(
        'Claim to ORCID',
        this.claimNotification
      )
    },
  },
}
</script>
<template>
  <loading v-if="loading" />
  <div v-else-if="signedIn === false || orcidAuthorized === false">
    <notification
      v-if="showOrcidAuthTimeoutNotification"
      notification-style="error"
    >
      The server is taking a long time to respond. This can be caused by poor
      connectivity or an error with the server. Please try again later.
    </notification>
    <br v-if="showOrcidAuthTimeoutNotification" />

    <h3 class="no_top_margin">Link this article to your ORCID iD</h3>
    <p class="small">
      Give permission for Europe PMC to access your ORCID record and claim this
      article. You will then be returned to the article.
    </p>
    <button @click="initOrcidAuth">Give ORCID permission to claim</button>
    <h3>Don't have an ORCID iD?</h3>
    <p class="small">
      ORCID provides a persistent digital identifier (an ORCID iD) that you own
      and control, and that distinguishes you from every other researcher. You
      can connect your iD with your professional information — publications,
      affiliations, grants, peer review and more. Learn more at
      <a href="https://orcid.org">orcid.org</a>
    </p>
  </div>
  <div v-else>
    <notification
      v-if="claimNotification === 'justClaimed'"
      notification-style="confirmation"
    >
      You have successfully added this publication to your ORCID profile.
    </notification>
    <notification
      v-else-if="claimNotification === 'alreadyClaimed'"
      notification-style="informative"
    >
      This article has already been claimed to your ORCID profile.
    </notification>
    <notification
      v-else-if="claimNotification === 'claimFailed'"
      notification-style="error"
    >
      Something went wrong. Claiming this article to your ORCID profile failed.
      Please try again later.
    </notification>
    <h3 v-else class="no_top_margin">Claim this record to your ORCID</h3>
    <br v-if="claimNotification !== null" />

    <citation
      :citation="citation"
      :are-links-disabled="true"
      class="small botttom_margin"
    ></citation>

    <template
      v-if="
        claimNotification === 'alreadyClaimed' ||
        claimNotification === 'justClaimed'
      "
    >
      <router-link
        :to="{
          name: 'authorProfile',
          params: { orcid: loggedInOrcidId },
          hash: '#claimedArticles',
        }"
        class="author-profile-link semi-bold"
        @click="
          registerMatomoEventForArticle(
            'Claim to ORCID',
            'View list of claimed articles'
          )
        "
      >
        View my list of claimed articles
      </router-link>
      (It may take a few minutes for this article to appear).
    </template>
    <button v-else @click="claimOrcid">Claim to ORCID</button>
  </div>
</template>
<style scoped lang="scss">
.no_top_margin {
  margin-top: 0;
}
.botttom_margin {
  margin-bottom: $base-unit * 6;
}
.author-profile-link {
  display: inline-block;
}
</style>
