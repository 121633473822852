export async function convertLatexToMathMl(title) {
  const latexRegex = /\$\$(.*?)\$\$/g
  const latexParts = title.match(latexRegex)
  if (!latexParts) return title

  let updatedString = title
  try {
    await window.MathJax.startup.promise
  } catch (err) {}

  for (const part of latexParts) {
    try {
      const latexMatch = part.slice(2, -2) // Remove the $$ delimiters
      try {
        const node = await window.MathJax.tex2chtmlPromise(latexMatch)
        updatedString = updatedString.replace(part, node.outerHTML)
      } catch (err) {}
    } catch (err) {
      throw new Error(err)
    }
  }

  return updatedString
}

export function stripMMLPrefix(inputString) {
  return inputString.replace(/mml:/g, '')
}
