<script>
import { mapState, mapMutations } from 'vuex'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action, ContentSection, List } from 'epmc-patterns/components/v2'
import Citation from '@/templates/Citation'

export default {
  components: {
    Action,
    Citation,
    ContentSection,
    List,
  },
  computed: mapState('exportList', ['exportList']),
  methods: {
    ...mapMutations('exportList', ['removeArticles', 'removeSelectedArticle']),
    registerMatomoEvent,
  },
}
</script>
<template>
  <content-section
    :section="{
      heading: 'h3',
      title: 'Export list (' + exportList.length + ')',
    }"
  >
    <action
      id="clear--all-recentSearches"
      slot="rightSlot"
      @click="
        removeArticles()
        $emit('remove-articles')
      "
    >
      Clear list<i slot="icon" class="fas fa-times-circle" />
    </action>
    <list :list="exportList">
      <citation slot-scope="{ item }" :citation="item">
        <action
          slot="leftSlot"
          @click="
            removeSelectedArticle(item)
            registerMatomoEvent('Article', 'Export', 'Removed from export list')
          "
        >
          <i slot="icon" class="fas fa-times" />Remove
        </action>
      </citation>
    </list>
  </content-section>
</template>
