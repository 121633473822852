<script>
import Sticky from '@/helpers/sticky'
import { Action } from 'epmc-patterns/components/v2'
import ActionBar from '@/templates/ActionBar'
import PopupShadow from '@/templates/PopupShadow'
/* Slot style for expandable menus: 
  <component slot="menu" ref="Menu title"/>
  <component slot="menu1" ref="Menu 1 title"/>
  <component slot="menu2" ref="Menu 2 title"/>
*/

export default {
  components: {
    PopupShadow,
    Action,
    ActionBar,
  },
  mixins: [Sticky],
  props: {
    screenWidth: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      expandMenu: null,
      menus: [],
    }
  },
  watch: {
    screenWidth() {
      if (this.screenWidth > 799) {
        this.expandMenu = null
      }
    },
  },
  created() {
    const slots = this.$slots
    const menus = []
    const arrs = Object.keys(slots).map(
      (s) => s.startsWith('menu') && slots[s][0]
    )
    arrs.map((a) => menus.push(a))
    this.menus = menus
  },
}
</script>
<template>
  <div
    v-if="screenWidth <= 1000"
    id="sticky-filter"
    class="sticky-footer-container sticky-footer sticky"
  >
    <div class="grid-row">
      <div class="col-16">
        <div class="sticky-footer sticky" style="z-index: 5">
          <div class="mobile-menu-buttons">
            <template v-if="screenWidth <= 799">
              <action
                v-for="menu in menus"
                v-show="!expandMenu || expandMenu === menu.data.ref"
                :key="menu.data.ref"
                :class="expandMenu === menu.data.ref ? 'open' : ''"
                icon-pos="right"
                @click="
                  expandMenu
                    ? (expandMenu = null)
                    : (expandMenu = menu.data.ref)
                "
              >
                {{ menu.data.ref
                }}<i
                  slot="icon"
                  :class="[
                    'fas',
                    expandMenu ? 'fa-angle-down' : 'fa-angle-right',
                  ]"
                />
              </action>
            </template>
            <action-bar
              v-show="!expandMenu"
              :size="'mobile'"
              @notify="(n) => $emit('notify', n)"
            />
            <action v-show="expandMenu" @click="expandMenu = null">
              <i slot="icon" class="fas fa-times" />
            </action>
          </div>
          <div
            v-if="screenWidth <= 799 && expandMenu"
            class="mobile-menu-content"
          >
            <template v-for="menu in menus">
              <slot
                v-if="menu.data.ref === expandMenu"
                :name="menu.data.slot"
              />
            </template>
          </div>
        </div>
        <popup-shadow v-if="expandMenu" @unmount="expandMenu = null" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.sticky-footer-container {
  box-shadow: 2px 0 4px 0 lighten($epmc-darker-grey, 50%);
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.sticky-footer {
  background-color: $epmc-darker-blue;
  bottom: 0;
  z-index: 5;
  width: 100%;
  text-align: center;
}
.mobile-menu-buttons {
  z-index: 6;
  margin: 0 auto;
  padding-top: $base-unit * 3.5;
  padding-bottom: $base-unit * 3.5;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > div {
    margin-left: auto;
  }
  & > .action,
  & > .action:hover,
  & > div > .action,
  & > div > .action:hover {
    color: $epmc-white;
    font-size: $base-unit * 3.75;
    i {
      color: $epmc-white !important;
      font-size: $base-unit * 5;
      vertical-align: $base-unit * -0.5;
    }
    & + .action {
      margin-left: $base-unit * 5;
      &.open {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 500px) {
      & + .action {
        margin-left: $base-unit * 6;
      }
      .mobile-hide {
        display: inline-block;
        width: 0;
        height: 0;
        line-height: 0;
        font-size: 0;
      }
    }
  }
}
.mobile-menu-content {
  z-index: 6;
  background-color: $epmc-white;
  text-align: left;
  padding-top: 1px;
}
</style>
