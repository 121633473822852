<script>
import { Modal } from 'epmc-patterns/components/v2'
import { SEARCH_QUERY_LIMIT } from '@/config'

export default {
  components: { Modal },
  props: {
    queryLength: {
      type: Number,
      default: 0,
    },
    context: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTermLimit: SEARCH_QUERY_LIMIT,
    }
  },
  methods: {
    close(e) {
      this.$emit('close', e)
    },
  },
}
</script>

<template>
  <modal
    id="search-term-character-limit-modal"
    class="large"
    :close-when-clicking-outside="true"
    @close="close"
  >
    <template slot="title">Character limit reached</template>
    Your query is {{ queryLength }} characters long, and the
    {{ context }} feature has a limit of {{ searchTermLimit }} characters. To
    continue, shorten your query
    <div class="button-group">
      <a class="secondary-button" href="/ContactUs">Contact Help Desk</a>
      <a @click="close"><button>Continue</button></a>
    </div>
  </modal>
</template>
<style scoped lang="scss">
.button-group {
  margin-top: $base-unit * 4;
  align-items: center;
  .secondary-button {
    margin-left: $base-unit * 4;
  }
}
</style>
